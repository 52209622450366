import Title from "./../components/Title";

const BlockSteps = () => {
  return (
    <>
      <div className="cur mt-12 h-12 md:mt-16 md:h-16">
        <svg
          viewBox="0 0 1280 192"
          preserveAspectRatio="none"
          className="h-full w-full fill-current text-gray-100"
        >
          <path
            d="M-216,1135.969s320,192,640,192,640-192,640-192Z"
            transform="translate(1064 1327.968) rotate(180)"
          />
        </svg>
      </div>

      <div className="bg-gray-100 px-4 pb-16 pt-16 md:px-6 lg:px-8">
        <div className="mx-auto w-full max-w-7xl">
          <div className="mb-16">
            <Title title="We&rsquo;ll find you a market leading mortgage rate and help you through the process" />
          </div>

          <p className="mb-2 text-left text-xl font-bold text-gray-900">
            How we work:
          </p>
          <div class="grid gap-8 md:grid-cols-2 lg:grid-cols-2">
            <div class="flex flex-col items-center rounded-lg border border-gray-500 bg-white p-4 shadow-md shadow-gray-500/25 lg:rounded-[2rem]">
              <div class="mb-2 text-center text-5xl font-bold text-gray-900">
                1
              </div>
              <p class="mb-2 text-center text-2xl font-bold text-gray-900">
                Speak to one of our new build mortgage advisors
              </p>
              <p class="mb-0 text-center text-lg text-gray-900">
                Within just a few minutes, we&rsquo;ll help assess your
                affordability, giving you peace of mind as you search for your
                ideal Taylor Wimpey home
              </p>
            </div>
            <div class="flex flex-col items-center rounded-lg border border-gray-500 bg-white p-4 shadow-md shadow-gray-500/25 lg:rounded-[2rem]">
              <div class="mb-2 text-center text-5xl font-bold text-gray-900">
                2
              </div>
              <p class="mb-2 text-center text-2xl font-bold text-gray-900">
                We will search the market
              </p>
              <p class="mb-0 text-center text-lg text-gray-900">
                Sit back and relax as we search the entire market to find the
                perfect new build mortgage tailored to your needs.
              </p>
            </div>
            <div class="flex flex-col items-center rounded-lg border border-gray-500 bg-white p-4 shadow-md shadow-gray-500/25 lg:rounded-[2rem]">
              <div class="mb-2 text-center text-5xl font-bold text-gray-900">
                3
              </div>
              <p class="mb-2 text-center text-2xl font-bold text-gray-900">
                Submit your application
              </p>
              <p class="mb-0 text-center text-lg text-gray-900">
                If you agree, we&rsquo;ll handle submitting your mortgage
                application for you. One of our dedicated mortgage advisers will
                guide you through every step of the process.
              </p>
            </div>
            <div class="flex flex-col items-center rounded-lg border border-gray-500 bg-white p-4 shadow-md shadow-gray-500/25 lg:rounded-[2rem]">
              <div class="mb-2 text-center text-5xl font-bold text-gray-900">
                4
              </div>
              <p class="mb-2 text-center text-2xl font-bold text-gray-900">
                Exchange and completion
              </p>
              <p class="mb-0 text-center text-lg text-gray-900">
                After your mortgage offer is issued, we&rsquo;ll work closely
                with all parties to ensure a swift exchange of contracts and
                make sure you&rsquo;re fully prepared for the completion of your
                new home.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlockSteps;
