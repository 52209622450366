import LabelCheckMark from "./LabelCheckMark";
import Tooltip from "../Tooltip";
import Alert from "../Alert";

function Textarea({
  name,
  label,
  labelCheckMark = true,
  help,
  value,
  onChange,
  error,
}) {
  const labelCheckMarkValue = value && !error ? true : false;

  return (
    <div className="">
      <div className="flex items-start justify-between">
        <div className="flex items-start">
          {labelCheckMark && <LabelCheckMark value={labelCheckMarkValue} />}
          <label
            htmlFor={name}
            className="text-md block font-medium text-black dark:text-white"
          >
            {label}
          </label>
        </div>
        {help && <Tooltip content={help} />}
      </div>

      <div className="mt-1">
        <textarea
          name={name}
          id={name}
          value={value ? value : ""}
          rows="4"
          className=" text-md block w-full rounded-lg border-gray-400 bg-white font-bold text-black focus:border-gray-500 focus:ring-gray-500 dark:border-gray-600 dark:bg-black dark:text-white"
          aria-describedby={`${name}-help`}
          onChange={onChange}
        />
      </div>
      {error && <Alert type="danger" text={error} />}
    </div>
  );
}

export default Textarea;
