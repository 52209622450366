import Title from "./Title";
import { ButtonDefault } from "./Buttons";
import HomePageImage from "../img/home-page-image.jpg";

const BlockBudget = () => {
  return (
    <>
      <div className="cur h-12 md:h-16">
        <svg
          viewBox="0 0 1280 192"
          preserveAspectRatio="none"
          className="h-full w-full fill-current text-gray-100"
        >
          <path
            d="M1139,2293.461H-141s160,96,320,96,200-95.682,320-96,200,192,320,192C939,2480.781,1139,2293.461,1139,2293.461Z"
            transform="translate(141 -2293.46)"
          ></path>
        </svg>
      </div>

      <div className="bg-white-100 px-4 py-16 md:px-6 lg:px-8">
        <div className="mx-auto w-full max-w-7xl">
          <div className="mb-8">
            <Title title="Try our mortgage calculators" />
          </div>

          <div className="mb-16">
            <p className="text-center text-lg font-bold  text-gray-900">
              Discover how much you can borrow using our interactive
              calculators.
            </p>
          </div>

          <div className="mb-8 grid gap-8 md:grid-cols-2">
            <div className="flex flex-col divide-y divide-gray-500 rounded-lg border border-gray-500 shadow-md shadow-gray-500/25 lg:rounded-[2rem]">
              <button
                className="w-full rounded-t-lg p-4 text-left hover:bg-gray-100 lg:rounded-t-[2rem]"
                onClick={() =>
                  (window.location.href =
                    "https://findmythreshold.io/find-budget")
                }
              >
                <p className="mb-1 text-xl font-bold uppercase text-gray-900">
                  Mortgage calculator
                </p>
                <p className="text-gray-900">
                  Find your budget with our easy to use mortgage payment
                  calculator.
                </p>
              </button>
              <button
                className="w-full p-4 text-left hover:bg-gray-100"
                onClick={() =>
                  (window.location.href =
                    "https://findmythreshold.io/find-mortgage")
                }
              >
                <p className="mb-1 text-xl font-bold uppercase text-gray-900">
                  Available rates
                </p>
                <p className="text-gray-900">
                  Search current mortgage deals based on your affordability to
                  find the right deal for you.
                </p>
              </button>
              <div className="w-full p-4 ">
                <ButtonDefault
                  color="red"
                  label="Get started"
                  onClick={() =>
                    (window.location.href =
                      "https://findmythreshold.io/find-budget")
                  }
                />
              </div>
            </div>
            <div
              className="h-48 rounded-lg bg-cover bg-center xs:h-64 md:h-auto lg:flex-auto lg:rounded-[2rem]"
              style={{ backgroundImage: `url(${HomePageImage})` }}
            ></div>
          </div>
        </div>
      </div>

      <div className="cur -mt-12 h-12 md:-mt-16 md:h-16">
        <svg
          viewBox="0 0 1280 192"
          preserveAspectRatio="none"
          className="h-full w-full fill-current text-white"
        >
          <path
            d="M-216,1135.969s320,192,640,192,640-192,640-192Z"
            transform="translate(1064 1327.968) rotate(180)"
          />
        </svg>
      </div>
    </>
  );
};

export default BlockBudget;
