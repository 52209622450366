import { useState } from "react";
import Title from "../components/Title";
import Hero from "../components/Hero";
import FormDeveloperLeads from "../components/FormDeveloperLeads";
import BlockRatings from "../components/BlockRatings";
import BlockAwards from "../components/BlockAwards";

function DeveloperLeadsPage(props) {
  const [formSubmitted, setFormSubmitted] = useState(false);

  return (
    <>
      <Hero />

      <div id="contact" className="scroll-mt-32">
        <div className="bg-white px-4 pb-16 pt-16 md:px-6 lg:px-8">
          <div className="mx-auto w-full max-w-3xl">
            <div className="mb-8">
              <Title
                title={!formSubmitted ? "New enquiry form" : "Thank you!"}
              />
            </div>

            {!formSubmitted && (
              <FormDeveloperLeads setFormSubmitted={setFormSubmitted} />
            )}
            {formSubmitted && (
              <div className="text-center text-black">
                <p className="text-lg ">
                  <span className="font-bold">Your enquiry has been sent!</span>
                </p>

                {/* <p className="text-lg ">
                  <span className="font-bold">
                    {renderDay(appointmentData.dayToCall)}
                  </span>
                  {appointmentData.timeToCall > 2 &&
                  appointmentData.timeToCall < 6
                    ? ","
                    : "."}{" "}
                  <span className="font-bold">
                    {renderTime(appointmentData.timeToCall)}
                  </span>
                </p>
                <p className="mb-4">
                  ({renderDate(appointmentData.dayToCall)})
                </p>

                <p className="text-md mb-16">
                  An adviser will call you by phone on the number you have
                  provided to discuss your requirements
                </p> */}

                {/* <button
                type="button"
                className="ml-4 inline-flex items-center rounded-md border border-gray-400 bg-white px-4 py-2 text-sm text-black shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:border-gray-600 dark:bg-black dark:text-white dark:hover:bg-gray-900"
                onClick={() => navigate("/")}
              >
                Return
              </button> */}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* <BlockRatings /> */}

      <BlockAwards />

      {/* <ScrollToTop
        smooth
        component={<ArrowSmallUp />}
        className="!bottom-[16px] !right-[20px] flex items-center justify-center !rounded-full !bg-gray-100 !text-black !shadow-none hover:!bg-gray-200 dark:!bg-gray-600 dark:!text-white dark:hover:!bg-gray-700"
      /> */}
    </>
  );
}

export default DeveloperLeadsPage;
