import { useEffect, useState } from "react";
import { slice, take } from "lodash";
import moment from "moment";
import RadioGroup from "./RadioGroup";
import SingleCheckbox from "./SingleCheckbox";
import { ButtonSmall } from "../Buttons";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi2";

const AppointmentPicker = ({ data, setData, setAppointment }) => {
  const [formDataChange, setFormDataChange] = useState({});
  const [dateOptions, setDateOptions] = useState([]);
  const [dateOptionsPage, setDateOptionsPage] = useState(0);

  useEffect(() => {
    const dateGroupSize = 5;

    const slicedDates = slice([...dates], dateOptionsPage * dateGroupSize);
    const trimmedDateGroup = take(slicedDates, dateGroupSize);

    setDateOptions(trimmedDateGroup);
  }, [dateOptionsPage]);

  useEffect(() => {
    const { name, value } = formDataChange;

    if (name === "appointmentDay")
      setData({
        ...data,
        appointmentGroup: null,
        appointmentTime: null,
      });

    if (name === "appointmentCallSoon")
      setData({
        ...data,
        appointmentDay: null,
        appointmentGroup: null,
        appointmentTime: null,
      });

    if (
      name === "appointmentDay" ||
      name === "appointmentGroup" ||
      name === "appointmentTime"
    )
      if (data.appointmentCallSoon === 1)
        setData({
          ...data,
          appointmentCallSoon: 0,
        });
  }, [formDataChange]);

  useEffect(() => {
    const { appointmentDay, appointmentTime } = {
      ...data,
    };

    const getDateText = (value) => {
      return moment(value).format("Do MMMM, YYYY");
    };

    const getDayText = (value) => {
      return isToday(value)
        ? "Today"
        : isTomorrow(value)
        ? "Tomorrow"
        : moment(value).format("dddd");
    };

    const dayDifference = moment(appointmentDay).diff(moment(), "days");

    const filteredTime = timeSlots.filter(
      (timeSlot) => timeSlot.value === appointmentTime
    );

    const time = appointmentTime ? filteredTime[0].option : "";

    const text =
      appointmentDay !== null && appointmentTime !== null
        ? dayDifference >= 6
          ? `${getDateText(appointmentDay)}. ${time}`
          : `${getDayText(appointmentDay)}, ${time} (${getDateText(
              appointmentDay
            )})`
        : "Call as soon as possible";

    setAppointment({
      date:
        appointmentDay && appointmentTime
          ? moment(appointmentDay).format("YYYY-MM-DD")
          : moment().format("YYYY-MM-DD"),
      day:
        appointmentDay && appointmentTime
          ? getDayText(appointmentDay)
          : "Today",
      time,
      text,
    });
  }, [data]);

  const isToday = (value) => {
    const isToday = moment(value).date() === moment().date() ? true : false;
    return isToday;
  };

  const isTomorrow = (value) => {
    const isTomorrow =
      moment(value).date() === moment().add(1, "days").date() ? true : false;
    return isTomorrow;
  };

  const handleChange = ({ currentTarget }) => {
    const input = {
      name: currentTarget.name,
      value: currentTarget.value,
    };
    doAfterChange(input);
  };

  const handleRadioChange = ({ currentTarget }) => {
    const input = {
      name: currentTarget.name,
      value: parseInt(currentTarget.value),
    };
    doAfterChange(input);
  };

  const handleCheckboxChange = ({ currentTarget }) => {
    const input = {
      name: currentTarget.name,
      value: parseInt(currentTarget.value) === 0 ? 1 : 0,
    };
    doAfterChange(input);
  };

  const doAfterChange = (input) => {
    setData({ ...data, [input.name]: input.value });
    setFormDataChange(input);
  };

  const handleDateGroupSelect = (value) => {
    const page = value === "prev" ? dateOptionsPage - 1 : dateOptionsPage + 1;

    setDateOptionsPage(page);
  };

  const formatDate = (date) => {
    const label = isToday(date) ? (
      <p className="text-center">
        Today
        <br />
        <span className="text-sm font-normal">
          {moment(date).format("ddd")}
        </span>{" "}
        <span className="font-black">{moment(date).format("DD")}</span>{" "}
        <span className="font-normal">{moment(date).format("MMM")}</span>
      </p>
    ) : isTomorrow(date) ? (
      <p className="text-center">
        Tomorrow
        <br />
        <span className="text-sm font-normal">
          {moment(date).format("ddd")}
        </span>{" "}
        <span className="font-black">{moment(date).format("DD")}</span>{" "}
        <span className="font-normal">{moment(date).format("MMM")}</span>
      </p>
    ) : (
      <p className="text-center">
        <span className="text-lg font-normal">
          {moment(date).format("ddd")}
        </span>
        <br />
        <span className="text-4xl font-black">{moment(date).format("DD")}</span>
        <br />
        <span className="text-2xl font-normal">
          {moment(date).format("MMM")}
        </span>
      </p>
    );

    return label;
  };

  const dates = [
    {
      _id: 0,
      value: moment().format("YYYY-MM-DD"),
      option: formatDate(moment().format()),
    },
    {
      _id: 1,
      value: moment().add(1, "days").format("YYYY-MM-DD"),
      option: formatDate(moment().add(1, "days").format()),
    },
    {
      _id: 2,
      value: moment().add(2, "days").format("YYYY-MM-DD"),
      option: formatDate(moment().add(2, "days").format()),
    },
    {
      _id: 3,
      value: moment().add(3, "days").format("YYYY-MM-DD"),
      option: formatDate(moment().add(3, "days").format()),
    },
    {
      _id: 4,
      value: moment().add(4, "days").format("YYYY-MM-DD"),
      option: formatDate(moment().add(4, "days").format()),
    },
    {
      _id: 5,
      value: moment().add(5, "days").format("YYYY-MM-DD"),
      option: formatDate(moment().add(5, "days").format()),
    },
    {
      _id: 6,
      value: moment().add(6, "days").format("YYYY-MM-DD"),
      option: formatDate(moment().add(6, "days").format()),
    },
    {
      _id: 7,
      value: moment().add(7, "days").format("YYYY-MM-DD"),
      option: formatDate(moment().add(7, "days").format()),
    },
    {
      _id: 8,
      value: moment().add(8, "days").format("YYYY-MM-DD"),
      option: formatDate(moment().add(8, "days").format()),
    },
    {
      _id: 9,
      value: moment().add(9, "days").format("YYYY-MM-DD"),
      option: formatDate(moment().add(9, "days").format()),
    },
    {
      _id: 10,
      value: moment().add(10, "days").format("YYYY-MM-DD"),
      option: formatDate(moment().add(10, "days").format()),
    },
    {
      _id: 11,
      value: moment().add(11, "days").format("YYYY-MM-DD"),
      option: formatDate(moment().add(11, "days").format()),
    },
    {
      _id: 12,
      value: moment().add(12, "days").format("YYYY-MM-DD"),
      option: formatDate(moment().add(12, "days").format()),
    },
    {
      _id: 13,
      value: moment().add(13, "days").format("YYYY-MM-DD"),
      option: formatDate(moment().add(13, "days").format()),
    },
    {
      _id: 14,
      value: moment().add(14, "days").format("YYYY-MM-DD"),
      option: formatDate(moment().add(14, "days").format()),
    },
    {
      _id: 15,
      value: moment().add(15, "days").format("YYYY-MM-DD"),
      option: formatDate(moment().add(15, "days").format()),
    },
    {
      _id: 16,
      value: moment().add(16, "days").format("YYYY-MM-DD"),
      option: formatDate(moment().add(16, "days").format()),
    },
    {
      _id: 17,
      value: moment().add(17, "days").format("YYYY-MM-DD"),
      option: formatDate(moment().add(17, "days").format()),
    },
    {
      _id: 18,
      value: moment().add(18, "days").format("YYYY-MM-DD"),
      option: formatDate(moment().add(18, "days").format()),
    },
    {
      _id: 19,
      value: moment().add(19, "days").format("YYYY-MM-DD"),
      option: formatDate(moment().add(19, "days").format()),
    },
  ];

  const checkTimeSlot = (time) => {
    const result = isToday(data.appointmentDay)
      ? moment.duration(moment().diff(moment(time))).asHours() > -1
        ? true
        : false
      : false;

    return result;
  };

  const timeSlots = [
    {
      _id: 0,
      group: 0,
      value: 0,
      option: "9:00am",
      disabled: checkTimeSlot(moment().hour(9).minute(0)),
    },
    {
      _id: 1,
      group: 0,
      value: 1,
      option: "9:30am",
      disabled: checkTimeSlot(moment().hour(9).minute(30)),
    },
    {
      _id: 2,
      group: 0,
      value: 2,
      option: "10:00am",
      disabled: checkTimeSlot(moment().hour(10).minute(0)),
    },
    {
      _id: 3,
      group: 0,
      value: 3,
      option: "10:30am",
      disabled: checkTimeSlot(moment().hour(10).minute(30)),
    },
    {
      _id: 4,
      group: 0,
      value: 4,
      option: "11:00am",
      disabled: checkTimeSlot(moment().hour(11).minute(0)),
    },
    {
      _id: 5,
      group: 0,
      value: 5,
      option: "11:30am",
      disabled: checkTimeSlot(moment().hour(11).minute(30)),
    },
    {
      _id: 6,
      group: 1,
      value: 6,
      option: "12:00pm",
      disabled: checkTimeSlot(moment().hour(12).minute(0)),
    },
    {
      _id: 7,
      group: 1,
      value: 7,
      option: "12:30pm",
      disabled: checkTimeSlot(moment().hour(12).minute(30)),
    },
    {
      _id: 8,
      group: 1,
      value: 8,
      option: "1:00pm",
      disabled: checkTimeSlot(moment().hour(13).minute(0)),
    },
    {
      _id: 9,
      group: 1,
      value: 9,
      option: "1:30pm",
      disabled: checkTimeSlot(moment().hour(13).minute(30)),
    },
    {
      _id: 10,
      group: 1,
      value: 10,
      option: "2:00pm",
      disabled: checkTimeSlot(moment().hour(14).minute(0)),
    },
    {
      _id: 11,
      group: 1,
      value: 11,
      option: "2:30pm",
      disabled: checkTimeSlot(moment().hour(14).minute(30)),
    },
    {
      _id: 12,
      group: 1,
      value: 12,
      option: "3:00pm",
      disabled: checkTimeSlot(moment().hour(15).minute(0)),
    },
    {
      _id: 13,
      group: 1,
      value: 13,
      option: "3:30pm",
      disabled: checkTimeSlot(moment().hour(15).minute(30)),
    },
    {
      _id: 14,
      group: 1,
      value: 14,
      option: "4:00pm",
      disabled: checkTimeSlot(moment().hour(16).minute(0)),
    },
    {
      _id: 15,
      group: 1,
      value: 15,
      option: "4:30pm",
      disabled: checkTimeSlot(moment().hour(16).minute(30)),
    },
    {
      _id: 16,
      group: 1,
      value: 16,
      option: "5:00pm",
      disabled: checkTimeSlot(moment().hour(17).minute(0)),
    },
    {
      _id: 17,
      group: 1,
      value: 17,
      option: "5:30pm",
      disabled: checkTimeSlot(moment().hour(17).minute(30)),
    },
    {
      _id: 18,
      group: 2,
      value: 18,
      option: "6:00pm",
      disabled: checkTimeSlot(moment().hour(18).minute(0)),
    },
    {
      _id: 19,
      group: 2,
      value: 19,
      option: "6:30pm",
      disabled: checkTimeSlot(moment().hour(18).minute(30)),
    },
    {
      _id: 20,
      group: 2,
      value: 20,
      option: "7:00pm",
      disabled: checkTimeSlot(moment().hour(19).minute(0)),
    },
  ];

  const getTimeSlots = (appointmentGroup) => {
    return timeSlots.filter((timeSlot) => appointmentGroup === timeSlot.group);
  };

  const checkTimeOfDay = (time) => {
    const result = isToday(data.appointmentDay)
      ? moment.duration(moment().diff(moment(time))).asHours() > -0.5
        ? true
        : false
      : false;

    return result;
  };

  return (
    <>
      <div className="mb-2 flex justify-center">
        <div className="w-full max-w-sm sm:max-w-full">
          <RadioGroup
            name="appointmentDay"
            label="Day"
            labelCheckMark={false}
            options={dateOptions}
            layout="date"
            toolTip={null}
            value={data.appointmentDay}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="mb-4 flex justify-center sm:justify-start">
        <div className="w-full max-w-sm sm:max-w-full">
          <div className="flex h-10 w-full gap-2">
            <ButtonSmall
              color="plain"
              label={<HiChevronLeft size={32} />}
              onClick={() => handleDateGroupSelect("prev")}
              disabled={dateOptionsPage === 0}
            />
            <ButtonSmall
              color="plain"
              label={<HiChevronRight size={32} />}
              onClick={() => handleDateGroupSelect("next")}
              disabled={dateOptionsPage === 3}
            />
          </div>
        </div>
      </div>

      {data.appointmentDay !== null ? (
        <>
          <div className="mb-4 flex justify-center">
            <div className="w-full max-w-sm sm:max-w-full">
              <RadioGroup
                name="appointmentGroup"
                label="Time of day"
                labelCheckMark={false}
                options={[
                  {
                    _id: 0,
                    value: 0,
                    option: "Morning",
                    disabled: checkTimeOfDay(moment().hour(12).minute(0)),
                  },
                  {
                    _id: 1,
                    value: 1,
                    option: "Afternoon",
                    disabled: checkTimeOfDay(moment().hour(17).minute(0)),
                  },
                  {
                    _id: 2,
                    value: 2,
                    option: "Evening",
                    disabled: checkTimeOfDay(moment().hour(19).minute(0)),
                  },
                ]}
                toolTip={null}
                value={data.appointmentGroup}
                onChange={handleRadioChange}
              />
            </div>
          </div>

          {data.appointmentGroup !== null ? (
            <div className="mb-4 flex justify-center">
              <div className="w-full max-w-sm sm:max-w-full">
                <RadioGroup
                  name="appointmentTime"
                  label="Time slot"
                  labelCheckMark={false}
                  options={getTimeSlots(data.appointmentGroup)}
                  layout="time"
                  toolTip={null}
                  value={data.appointmentTime}
                  onChange={handleRadioChange}
                />
              </div>
            </div>
          ) : null}
        </>
      ) : null}

      <div className="mb-0 flex justify-center sm:justify-start">
        <div className="w-full max-w-sm sm:max-w-full">
          <SingleCheckbox
            name="appointmentCallSoon"
            label="Call as soon as possible"
            size="large"
            itemsAlign="center"
            help={null}
            value={data.appointmentCallSoon}
            onChange={handleCheckboxChange}
          />
        </div>
      </div>
    </>
  );
};

export default AppointmentPicker;
