import { useContext } from "react";
import { ReactComponent as ThresholdLogo } from "../img/threshold-logo.svg";
import { ReactComponent as TaylorWimpeyLogo } from "../img/taylor-wimpey-logo.svg";
import AppContext from "../Context/AppContext";

const Header = () => {
  const { referral } = useContext(AppContext);

  return (
    <>
      <header className="fixed z-40 mb-8 w-full bg-white shadow-md shadow-gray-500/25">
        <div className="mx-auto max-w-7xl px-4 md:px-6 lg:px-8">
          <div className="flex h-16 items-center justify-between">
            <a
              href="https://www.thresholdmortgages.com/?utm_source=CAJU24&utm_medium=web_app"
              target="_blank"
            >
              <ThresholdLogo />
            </a>
            {referral === "t" ? (
              <div className="flex  items-center justify-end">
                <TaylorWimpeyLogo />
              </div>
            ) : null}
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
