import { useContext, useState, useEffect } from "react";
import Title from "../components/Title";
import Hero from "../components/Hero";
import Hero2 from "../components/Hero2";
import BlockSteps from "../components/BlockSteps";
import BlockBudget from "../components/BlockBudget";
import FormContact from "../components/FormContact";
import BlockAbout from "../components/BlockAbout";
import BlockAwards from "../components/BlockAwards";
import { ButtonDefault } from "../components/Buttons";
// import ScrollToTop from "react-scroll-to-top";
// import { ReactComponent as ArrowUp } from "../img/arrow-up.svg";
import AppContext from "../Context/AppContext";

function ContactPage(props) {
  const { appointment, referral, setModal } = useContext(AppContext);

  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    const handleConfirm = () => {
      setModal({ modalOpen: false });
    };

    const handleCancel = () => {
      setModal({ modalOpen: false });
    };

    referral === "t" &&
      setModal({
        modalOpen: true,
        modalOpenedFrom: "contact form",
        modalType: "alert",
        modalTitle: "Alert",
        modalContent: modalAlertContent(
          <>
            <p className="text-md mb-4">
              Threshold Mortgage Advice work independently from Taylor Wimpey UK
              Limited. Taylor Wimpey does not engage in financial services
              activity and is not responsible for any information or advice
              provided to you by Threshold Mortgage Advice.
            </p>
            <p className="text-md mb-4 font-bold">
              Please read the disclaimer at the end of the page for more
              information.
            </p>
          </>,
          handleConfirm,
          handleCancel
        ),
      });
  }, [referral]);

  const modalAlertContent = (alertText, handleConfirm, handleCancel) => {
    return (
      <div className="rounded-lg bg-white p-8 text-center text-black">
        {alertText}

        <div className="flex items-center justify-center">
          <ButtonDefault
            color="white"
            label="OK"
            onClick={() => handleConfirm()}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      {/* {referral === "t" ? <Hero /> : null} */}
      {referral === "t" ? <Hero2 /> : null}

      {referral === "t" ? <BlockSteps /> : null}

      {referral === "t" ? <BlockAbout /> : null}

      <div className="cur h-12 md:h-16">
        <svg
          viewBox="0 0 1280 192"
          preserveAspectRatio="none"
          className="h-full w-full fill-current text-gray-100"
        >
          <path
            d="M-216,1135.969s320,192,640,192,640-192,640-192Z"
            transform="translate(1064 1327.968) rotate(180)"
          />
        </svg>
      </div>

      <div id="contact" className="scroll-mt-16">
        <div className="bg-gray-100 px-4 py-16 md:px-6 lg:px-8">
          <div className="mx-auto w-full max-w-3xl">
            <div className="mb-16">
              <Title
                title={!appointment ? "Book an appointment" : "Thank you!"}
              />
            </div>

            {!formSubmitted && (
              <FormContact setFormSubmitted={setFormSubmitted} />
            )}
            {formSubmitted && (
              <div className="text-center text-black">
                <p className="text-lg ">
                  <span className="font-bold">Your enquiry has been sent!</span>
                </p>
                {/* 
                <p className="text-md">Your appointment has been booked for:</p>

                <p className="text-lg ">
                  <span className="font-bold">
                    {renderDay(appointmentData.dayToCall)}
                  </span>
                  {appointmentData.timeToCall > 2 &&
                  appointmentData.timeToCall < 6
                    ? ","
                    : "."}{" "}
                  <span className="font-bold">
                    {renderTime(appointmentData.timeToCall)}
                  </span>
                </p>
                <p className="mb-4">
                  ({renderDate(appointmentData.dayToCall)})
                </p>

                <p className="text-md mb-16">
                  An adviser will call you by phone on the number you have
                  provided to discuss your requirements
                </p> */}

                {/* <button
                type="button"
                className="ml-4 inline-flex items-center rounded-md border border-gray-400 bg-white px-4 py-2 text-sm text-black shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:border-gray-600 dark:bg-black dark:text-white dark:hover:bg-gray-900"
                onClick={() => navigate("/")}
              >
                Return
              </button> */}
              </div>
            )}
          </div>
        </div>
      </div>

      {referral === "t" ? <BlockBudget /> : null}

      {referral === "t" ? <BlockAwards /> : null}

      {/* <ScrollToTop
        smooth
        component={<ArrowUp />}
        className="!bottom-[16px] !right-[20px] flex items-center justify-center !rounded-full !bg-gray-100 !text-black !shadow-none hover:!bg-gray-200 dark:!bg-gray-600 dark:!text-white dark:hover:!bg-gray-700"
      /> */}
    </>
  );
}

export default ContactPage;
