import { ReactComponent as TrustpilotStars } from "../img/trustpilot-stars.svg";
import FamilyProtectionImage from "../img/family-protection.jpg";
import HomeInsuranceImage from "../img/home-insurance.jpg";
import MortgageProtectionImage from "../img/mortgage-protection.jpg";
import PropertyPurchaseImage from "../img/property-purchase.jpg";
import Title from "./../components/Title";

const BlockAbout = () => {
  return (
    <>
      <div className="cur h-12 md:h-16">
        <svg
          viewBox="0 0 1280 192"
          preserveAspectRatio="none"
          className="h-full w-full fill-current text-gray-100"
        >
          <path
            d="M1139,2293.461H-141s160,96,320,96,200-95.682,320-96,200,192,320,192C939,2480.781,1139,2293.461,1139,2293.461Z"
            transform="translate(141 -2293.46)"
          ></path>
        </svg>
      </div>

      <div className="bg-white px-4 py-16 md:px-6 lg:px-8">
        <div className="mx-auto w-full max-w-7xl">
          <div className="mb-16">
            <Title title="Why use Threshold" />
          </div>
          <div className="grid gap-8 md:grid-cols-2">
            <div>
              <p className="mb-4 text-lg text-black">
                Threshold Mortgages work hard on using their vast experience to
                find the mortgage that&rsquo;s right for you when buying your
                Taylor Wimpey home. We believe in treating clients fairly and
                honestly and pride ourselves on building lasting and reliable
                relationships.
              </p>
              <ul className="m-4 list-disc pl-4">
                <li className="mb-2 text-lg text-black">
                  We don&rsquo;t charge a fee for our service.
                </li>
                <li className="mb-2 text-lg text-black">
                  We have access to exclusive mortgage products not available on
                  the high street.
                </li>
                <li className="mb-2 text-lg text-black">
                  Our specialist new build mortgage advisers are available 7
                  days a week.
                </li>
                <li className="mb-2 text-lg text-black">
                  We offer a personalised service that supports you through
                  every step of the mortgage process, from application to the
                  successful completion of your new home.
                </li>

                <li className="mb-2 text-lg text-black ">
                  Our service is rated{" "}
                  <a
                    href="https://www.trustpilot.com/review/thresholdmortgages.com"
                    target="_blank"
                    rel="noopener nofollow noreferrer"
                    className="font-bold"
                  >
                    Excellent
                  </a>{" "}
                  <a
                    href="https://www.trustpilot.com/review/thresholdmortgages.com"
                    target="_blank"
                    rel="noopener nofollow noreferrer"
                    className="inline-flex"
                  >
                    <TrustpilotStars />
                  </a>{" "}
                  by our clients.
                </li>
              </ul>
              <p className="mb-2 text-lg text-black">
                You can find out more about Threshold Mortgage&rsquo;s award
                winning service by{" "}
                <a
                  className="text-threshred-500 underline"
                  href="https://www.thresholdmortgages.com/?utm_source=MPLTWOC22&utm_medium=web_app"
                  target="_blank"
                >
                  visiting our website
                </a>
                .
              </p>
              <p className="mb-16 text-lg text-black">
                Providing new build mortgage advice since 2004.
              </p>
            </div>

            <div className="grid auto-rows-fr grid-cols-2 gap-4 xs:max-h-[320px] md:max-h-[720px] md:grid-cols-1 lg:max-h-[1000px] lg:grid-cols-2">
              {/* <div className="lg:row-span-2"> */}
              <div className="shadow-gray-500/2 flex h-full flex-col overflow-hidden rounded-lg rounded-tl-[2rem] border border-gray-500 p-4 shadow-md md:rounded-t-[2rem] lg:rounded-tl-[2rem] lg:rounded-tr-lg">
                <img
                  class="h-full w-full rounded-lg rounded-tl-[2rem] object-cover md:rounded-t-[2rem] lg:rounded-tl-[2rem] lg:rounded-tr-lg"
                  src={PropertyPurchaseImage}
                  alt=""
                />
              </div>
              {/* </div>
              <div className="lg:col-start-2 lg:row-start-1"> */}
              <div className="flex h-full flex-col overflow-hidden rounded-lg rounded-tr-[2rem] border border-gray-500 p-4 shadow-md shadow-gray-500/25 md:rounded-lg lg:rounded-tr-[2rem]">
                <img
                  class="h-full w-full rounded-lg rounded-tr-[2rem] object-cover md:rounded-lg lg:rounded-tr-[2rem]"
                  src={FamilyProtectionImage}
                  alt=""
                />
              </div>
              {/* </div> */}
              {/* <div className="lg:col-start-2 lg:row-start-2"> */}
              <div className="flex h-full flex-col overflow-hidden rounded-lg rounded-bl-[2rem] border border-gray-500 p-4 shadow-md shadow-gray-500/25 md:rounded-lg lg:rounded-bl-[2rem]">
                <img
                  class="h-full w-full rounded-lg rounded-bl-[2rem] object-cover md:rounded-lg lg:rounded-bl-[2rem]"
                  src={HomeInsuranceImage}
                  alt=""
                />
              </div>
              {/* </div>
              <div className="lg:col-span-2 lg:col-start-1 lg:row-start-3"> */}
              <div className="flex h-full flex-col overflow-hidden rounded-lg rounded-br-[2rem] border border-gray-500 p-4 shadow-md shadow-gray-500/25 md:rounded-b-[2rem] lg:rounded-bl-lg lg:rounded-br-[2rem]">
                <img
                  class="h-full w-full rounded-lg rounded-br-[2rem] object-cover md:rounded-b-[2rem] lg:rounded-bl-lg lg:rounded-br-[2rem]"
                  src={MortgageProtectionImage}
                  alt=""
                />
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlockAbout;
