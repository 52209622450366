import { useLocation } from "react-router-dom";
import { ButtonDefault } from "./Buttons";
import HeroImage from "../img/hero-image.jpg";
import { ReactComponent as TrustpilotRating } from "../img/trustpilot-rating.svg";

const Hero2 = () => {
  let location = useLocation();

  const strapLine =
    location.pathname === "/enquiries"
      ? "Let's get qualified... Complete the enquiry form below"
      : "Fee free new build mortgage advice available 7 days a week";

  return (
    <>
      <section className="block-hero2">
        <div className="relative bg-white pb-24 pt-0 lg:py-24">
          <div className="img-container relative inset-0 lg:absolute lg:left-[calc(50%+1rem)] lg:block">
            <img
              className="h-[320px] w-full rounded-b-lg object-cover lg:h-full lg:rounded-l-[2rem] lg:rounded-br-none"
              src={HeroImage}
              alt=""
            />
          </div>

          <div className="mx-auto px-6 lg:max-w-7xl lg:px-8">
            {/* <!-- <div className="grid gap-8 lg:grid-cols-2"> --> */}
            <div className="mt-8 flex w-full flex-col items-start justify-center lg:mt-0 lg:h-72 lg:w-[calc(50%-1rem)]">
              <h2 className="font-body mb-8 text-left text-4xl font-bold text-gray-900">
                {strapLine}
              </h2>
              <p className="mb-8 text-left text-xl font-bold text-gray-900">
                Speak to a specialist mortgage adviser now!
              </p>
              <div className="mb-16 flex flex-col gap-4 sm:flex-row sm:gap-8">
                <ButtonDefault
                  color="red"
                  label="Book an appointment"
                  onClick={() => (window.location.href = "#contact")}
                />
                <ButtonDefault
                  color="plainDark"
                  label="03300 249 115"
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="h-4 w-4 sm:mr-2"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  }
                  onClick={() => (window.location.href = "tel:03300249115")}
                />
              </div>
              {/* <div className="flex w-full flex-col items-start justify-between gap-4 xs:flex-row"> */}
              <a
                href="https://www.trustpilot.com/review/thresholdmortgages.com"
                target="_blank"
                rel="noopener nofollow noreferrer"
                className="mb-8"
              >
                <TrustpilotRating />
              </a>

              <p className="mb-0 text-sm text-gray-900">
                *
                <a href="#disclaimer" className="underline">
                  Disclaimer
                </a>
              </p>
              {/* </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero2;
