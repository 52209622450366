import React, { Fragment } from "react";

const getDefaultClasses = () => {
  return "relative inline-flex items-center justify-center min-w-[96px] leading-none rounded-full uppercase font-bold whitespace-nowrap disabled:text-gray-400 disabled:bg-gray-200 disabled:dark:bg-gray-800 disabled:dark:text-gray-600 disabled:cursor-not-allowed";
};

const getBackgroundClasses = (color) => {
  switch (color) {
    case "red":
      return "bg-threshred-500 text-gray-100 hover:bg-threshred-600 focus:outline-none focus:ring-2 focus:ring-threshred-500 focus:ring-offset-2 dark:hover:bg-threshred-400";

    case "redOutline":
      return "bg-threshred-500/10 text-threshred-500 border border-threshred-500 hover:bg-threshred-500/20 focus:outline-none focus:ring-2 focus:ring-threshred-500 focus:ring-offset-2 dark:hover:bg-threshred-400";

    case "indigo":
      return "bg-indigo-500 text-white hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 dark:hover:bg-indigo-400";
    case "emerald":
      return "bg-emerald-500 text-white hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 dark:hover:bg-emerald-400";
    case "sky":
      return "bg-sky-500 text-white hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 dark:hover:bg-sky-400";
    case "white":
      return "border border-gray-600 bg-white text-gray-600 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 dark:border-gray-400 dark:bg-black dark:text-gray-400 dark:hover:bg-gray-900 dark:focus:ring-gray-400 focus:ring-offset-white dark:focus:ring-offset-black";
    case "plain":
      return "bg-gray-100 text-gray-900 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 focus:ring-offset-2 focus:ring-offset-white";

    case "plainDark":
      return "bg-gray-800 text-gray-100 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-800 focus:ring-offset-2 focus:ring-offset-white ";

    case "plainActive":
      return "bg-indigo-500 text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-white";
  }
};

export function ButtonDefault({
  color,
  label,
  icon = null,
  onClick,
  disabled = false,
}) {
  return (
    <button
      type="button"
      className={`${getDefaultClasses()} px-6 py-3 text-lg ${getBackgroundClasses(
        color
      )}`}
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
      {label}
    </button>
  );
}

export function ButtonLarge({ color, label, onClick, disabled = false }) {
  return (
    <button
      type="button"
      className={`${getDefaultClasses()} px-8 py-4 text-2xl ${getBackgroundClasses(
        color
      )}`}
      onClick={onClick}
      disabled={disabled}
      selected={true}
    >
      {label}
    </button>
  );
}

export function ButtonSmall({ color, label, onClick, disabled = false }) {
  return (
    <button
      type="button"
      className={`${getDefaultClasses()} px-4 py-2 text-sm ${getBackgroundClasses(
        color
      )}`}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
}
