import { useContext } from "react";
import moment from "moment";
import AppContext from "../Context/AppContext";

function Footer() {
  const { referral } = useContext(AppContext);

  return (
    <>
      <footer id="disclaimer">
        <>
          <div className="bg-gray-800 px-4 py-16 md:px-6 lg:px-8">
            <div className="mx-auto w-full max-w-4xl">
              <div className="mb-8 flex flex-col items-center gap-4 text-center text-sm text-white">
                <p>
                  Threshold Mortgage Advice is a trading name of Threshold
                  Financial Services Limited.
                </p>
                <p>
                  Registered in England. Registered number 5321241. Registered
                  Address: 1 & 2 The Brookside Centre, Auckland Road,
                  Southampton, Hampshire, SO15 0SD.
                </p>

                <p>
                  Threshold Financial Services Limited is an appointed
                  representative of The Openwork Partnership, a trading style of
                  Openwork Limited which is authorised and regulated by the
                  Financial Conduct Authority.
                </p>

                {referral === "t" && (
                  <>
                    <p>
                      This page is provided to you by Threshold Mortgage Advice
                      and the website is hosted by Threshold Mortgage Advice.
                      Threshold Mortgage Advice provides mortgage advisory and
                      arranging services and is an appointed representative of
                      The Openwork Partnership, a trading style of Openwork
                      Limited who is authorised and regulated by the Financial
                      Conduct Authority.
                    </p>
                    <p>
                      Taylor Wimpey UK Limited (&lsquo;Taylor Wimpey&rsquo;)
                      works independently from Threshold Mortgage Advice, is not
                      a member of the same group as Threshold Mortgage Advice
                      and will not receive a fee or any other reward if you
                      contact Threshold Mortgage Advice (including by scanning
                      the QR code or otherwise). Taylor Wimpey does not engage
                      in financial services activity and is not responsible for
                      any information or advice provided to you by Threshold
                      Mortgage Advice.
                    </p>
                    <p>
                      If you contact Threshold Mortgage Advice and/or are
                      directed to our website in any way, you do so at your own
                      risk, and you will be subject to the terms and conditions
                      of Threshold Mortgage Advice. Please refer to Threshold
                      Mortgage Advice&rsquo;s terms and conditions and privacy
                      policy prior to using Threshold Mortgage Advice.
                    </p>
                    <p>
                      Except in relation to the use of any Taylor Wimpey logo
                      and/or branding, Taylor Wimpey does not endorse nor
                      approve any of the content on the Threshold Mortgage
                      Advice website or otherwise. Taylor Wimpey makes no
                      representation, warranty or commitment and shall have no
                      liability or obligation whatsoever in relation to the
                      content or use of, or correspondence with the Threshold
                      Mortgage Advice website or any transactions completed, any
                      contract entered into by you with Threshold Mortgage
                      Advice, or any act or omission of Threshold Mortgage
                      Advice.
                    </p>
                  </>
                )}

                <p>
                  The information on this website is for use of residents of the
                  United Kingdom only. No representations are made as to whether
                  the information is applicable or available in any other
                  country which may have access to it.
                </p>
              </div>

              <div className="flex justify-center text-sm text-white">
                <p>
                  &copy; Copyright {moment().format("YYYY")} - Threshold
                  Financial Services
                </p>
              </div>
            </div>
          </div>

          <div
            style={{
              position: "absolute",
              width: "1px",
              height: "1px",
              padding: "0",
              margin: "-1px",
              overflow: "hidden",
              clip: "rect(0, 0, 0, 0)",
              whiteSpace: "nowrap",
              borderWidth: "0",
            }}
          >
            <p>
              <a href="www.pearldesign.co.uk" target="_blank">
                Website design
              </a>{" "}
              by Pearl Design
            </p>
          </div>
        </>
      </footer>
    </>
  );
}
export default Footer;
